import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | The Bungalow Kitchen",
  pageDescription:
    "Discover how a fine-dining restaurant maintains an amazing guest experience thanks to SpotOn’s precise and transparent insights.",
  title: "Creating better work experiences with SpotOn Teamwork",
  subTitle: `How The Bungalow Kitchen uses SpotOn to make decisions with confidence and ease.`,
  businessName: "The Bungalow Kitchen",
  industry: "Restaurant, fine dining",
  location: "Long Beach, California",
  products: [spotonProducts.RESTAURANT, spotonProducts.LABOR_MANAGEMENT],
  description:
    "The Bungalow Kitchen is an upscale restaurant that prides itself in crafting a memorable ambiance for guests. Through SpotOn technology, general manager Lino Suazo can gain performance metrics for quick and precise decisions, conduct employee tip distribution and scheduling, and even update the menu in minutes.",
  quoteData: {
    imgName: "the-bungalow-kitchen.png",
    quote: `“SpotOn Teamwork provides a bunch of key metrics, like forecasted labor percentage. In the past, you would take a pen and pencil to figure it out. Now all you have to do is make a schedule and metrics populate on a day-by-day basis. It saves nearly two and a half hours most work days.”`,
    personName: "Lino Suazo",
    personTitle: "General Manager, The Bungalow Kitchen",
  },
  goal: `Since each guest's experience relies on servers working without worry, Suazo wanted a POS to help workers make efficient, data-driven decisions. He was also looking to focus more on the dining experience instead of paperwork that took a few hours of his time each day.`,
  solution: `Suazo can make changes to shift schedules and even the menu at a moment's notice thanks to SpotOn. And through SpotOn Teamwork, tip distribution happens automatically to enhance the staff experience. With everything going smoothly, the Bungalow can focus on crafting a memorable time for customers.`,
  results: {
    title: `The results`,
    stats: [
      { title: `5-7 hours`, description: `saved on weekly operations` },
      {
        title: `5-10 minutes`,
        description: `faster order times`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Financing chef-driven concepts",
    imageName: "bardo-and-vana.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-07-07",
    href: "/case-studies/bardo-and-vana",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "Fine dining reimagined",
    imageName: "imaginate-restaurant.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-21",
    href: "/case-studies/imaginate-restaurant",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "An old-school restaurant leverages new tech",
    imageName: "bartolinos-fine-italian-cuisine.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-06-07",
    href: "/case-studies/bartolinos-fine-italian-cuisine",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
